import * as ethers from "ethers";
import LinearStaking from "../abis/LinearStaking.json";
import erc20InvestmentTokens from "../constants/ERC20InvestmentTokens";
import Web3 from "web3";

export async function depositTreasuryToken () {
    try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const selectedAccount = accounts[0];
        const provider = new ethers.BrowserProvider(window.ethereum);
        const signer = await provider.getSigner(selectedAccount);
        const network = await provider.getNetwork();

        const stakingContract = new ethers.Contract(erc20InvestmentTokens[network.chainId].LINEAR_STAKING_ADDRESS, LinearStaking, signer);

        const tx = await stakingContract.depositTreasuryTokens();
        await tx.wait();
    } catch (error) {
        console.error('Error writing to smart contract:', error);
    }
}

export async function stake (stakeAmount) {
    try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const selectedAccount = accounts[0];
        const provider = new ethers.BrowserProvider(window.ethereum);
        const signer = await provider.getSigner(selectedAccount);
        const network = await provider.getNetwork();

        const stakingContract = new ethers.Contract(erc20InvestmentTokens[network.chainId].LINEAR_STAKING_ADDRESS, LinearStaking, signer);

        const tx = await stakingContract.stake(Web3.utils.toWei(stakeAmount, "ether"));
        await tx.wait();
    } catch (error) {
        console.error('Error writing to smart contract:', error);
    }
}

export async function unstake (amount) {
    try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const selectedAccount = accounts[0];
        const provider = new ethers.BrowserProvider(window.ethereum);
        const signer = await provider.getSigner(selectedAccount);
        const network = await provider.getNetwork();

        const stakingContract = new ethers.Contract(erc20InvestmentTokens[network.chainId].LINEAR_STAKING_ADDRESS, LinearStaking, signer);

        const tx = await stakingContract.unstake(Web3.utils.toWei(amount, "ether"));
        await tx.wait();
    } catch (error) {
        console.error('Error writing to smart contract:', error);
    }
}

export async function claim () {
    try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const selectedAccount = accounts[0];
        const provider = new ethers.BrowserProvider(window.ethereum);
        const signer = await provider.getSigner(selectedAccount);
        const network = await provider.getNetwork();

        const stakingContract = new ethers.Contract(erc20InvestmentTokens[network.chainId].LINEAR_STAKING_ADDRESS, LinearStaking, signer);

        const tx = await stakingContract.claim();
        await tx.wait();
    } catch (error) {
        console.error('Error writing to smart contract:', error);
    }
}

export async function getCurrentStakingReward () {
    try {
        const provider = new ethers.BrowserProvider(window.ethereum);
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const selectedAccount = accounts[0];
        const network = await provider.getNetwork();

        const linearStaking = new ethers.Contract(erc20InvestmentTokens[network.chainId].LINEAR_STAKING_ADDRESS, LinearStaking, provider);

        return await linearStaking.currentUserRewards(selectedAccount);

    } catch (error) {
        console.error('Error writing to smart contract:', error);
    }
}

export async function getCurrentStakingRewardPerToken () {
    try {
        const provider = new ethers.BrowserProvider(window.ethereum);
        const network = await provider.getNetwork();

        const linearStaking = new ethers.Contract(erc20InvestmentTokens[network.chainId].LINEAR_STAKING_ADDRESS, LinearStaking, provider);

        return await linearStaking.currentRewardsPerToken();

    } catch (error) {
        console.error('Error writing to smart contract:', error);
    }
}

export async function getTotalStaked () {
    try {
        const provider = new ethers.BrowserProvider(window.ethereum);
        const network = await provider.getNetwork();

        const linearStaking = new ethers.Contract(erc20InvestmentTokens[network.chainId].LINEAR_STAKING_ADDRESS, LinearStaking, provider);

        return await linearStaking.totalStaked();

    } catch (error) {
        console.error('Error writing to smart contract:', error);
    }
}

export async function getTotalStakedByAddress () {
    try {
        const provider = new ethers.BrowserProvider(window.ethereum);
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const selectedAccount = accounts[0];
        const network = await provider.getNetwork();

        const linearStaking = new ethers.Contract(erc20InvestmentTokens[network.chainId].LINEAR_STAKING_ADDRESS, LinearStaking, provider);

        return await linearStaking.userStake(selectedAccount);

    } catch (error) {
        console.error('Error writing to smart contract:', error);
    }
}
