import React, {useState} from 'react';
import '../assets/scss/_create-token.scss';
import {Button, Card, Divider, Input, Switch, message} from "antd";
import NetworkSelect from "../components/token/NetworkSelect";
import {handleCreateToken} from "../ethers/ContractFactory";
import LoadingModal from "../components/modals/LoadingModal";
import {useLocation, useNavigate} from 'react-router-dom';
import {z} from 'zod';

const tokenSchema = z.object({
    tokenName: z.string().min(1, "Token name is required"),
    tokenTicker: z.string().min(1, "Token ticker is required"),
    initialSupply: z.number().min(1, "Initial supply must be at least 1"),
    burnable: z.boolean(),
    mintable: z.boolean(),
    pausable: z.boolean(),
    projectName: z.string().min(1, "Project name is required")
});

export default function TokenCreation() {

    const navigate = useNavigate();

    const location = useLocation();
    const projectName = location.state.projectName;

    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        tokenName: '',
        tokenTicker: '',
        initialSupply: '',
        burnable: false,
        mintable: false,
        pausable: false,
        projectName: projectName
    });
    const [errors, setErrors] = useState({});

    const handleChange = (field) => (e) => {
        const value = e.target ? e.target.value : e;
        const parsedValue = field === 'initialSupply' ? parseFloat(value) : value;
        setFormData({
            ...formData,
            [field]: parsedValue
        });
        try {
            tokenSchema.shape[field].parse(parsedValue);
            setErrors({
                ...errors,
                [field]: undefined
            });
        } catch (error) {
            if (error instanceof z.ZodError) {
                setErrors({
                    ...errors,
                    [field]: error.errors[0].message
                });
            }
        }
    };

    const handleSwitchChange = (field) => (checked) => {
        setFormData({
            ...formData,
            [field]: checked
        });
    };

    const handleTokenCreation = async () => {
        try {
            tokenSchema.parse(formData);
        } catch (error) {
            if (error instanceof z.ZodError) {
                const fieldErrors = error.errors.reduce((acc, curr) => {
                    acc[curr.path[0]] = curr.message;
                    return acc;
                }, {});
                setErrors(fieldErrors);
                return;
            }
        }

        setIsLoading(true);
        const tx = await handleCreateToken(
            formData.tokenName,
            formData.tokenTicker,
            formData.projectName,
            formData.initialSupply,
            formData.burnable,
            formData.mintable,
            formData.pausable
        );
        setIsLoading(false);

        if (tx !== undefined) {
            const errorMsg = tx.toString().split('(');
            message.error(errorMsg[0]);
        } else {
            navigate('/project?id=' + projectName);
        }
    };

    console.log('IS BURNABLE ' + formData.burnable)

    return (
        <>
            <LoadingModal show={isLoading} text='Token creation in process...' />
            <div className="main-container">
                <div className='create-token'>
                    <div>
                        <div className="-title">
                            Token creation page
                        </div>
                        <div className="-text">
                            This page helps you setup your launch by creating a native token for your project. The token you create will be distributed to participants of your token launch. Start by selecting the token's native chain and provide the necessary information to continue.
                        </div>
                    </div>
                    <div>
                        <div className="-text black offset">Select Applicable Chains</div>
                        <NetworkSelect />
                    </div>
                    <Divider />
                    <div>
                        <div className="-title s">Token Basic Information</div>

                        <div className="-text">
                            Add the token's basic information. The detailed you provide will be displayed in the Launch Page and help users locate the token in their wallet.
                        </div>
                    </div>

                    <div>
                        <div className="-text black offset">Token name</div>
                        <Input
                            placeholder='Token name'
                            value={formData.tokenName}
                            onChange={handleChange('tokenName')}
                            status={errors.tokenName ? 'error' : ''}
                        />
                        {errors.tokenName && <div className="-text error">{errors.tokenName}</div>}
                    </div>
                    <div>
                        <div className="-text black offset">Token ticker</div>
                        <Input
                            placeholder='Token ticker'
                            value={formData.tokenTicker}
                            onChange={handleChange('tokenTicker')}
                            status={errors.tokenTicker ? 'error' : ''}
                        />
                        {errors.tokenTicker && <div className="-text error">{errors.tokenTicker}</div>}
                    </div>
                    <div>
                        <div className="-text black offset">Total supply</div>
                        <Input
                            type='number'
                            step='1'
                            placeholder='Total supply'
                            value={formData.initialSupply}
                            onChange={handleChange('initialSupply')}
                            status={errors.initialSupply ? 'error' : ''}
                        />
                        {errors.initialSupply && <div className="-text error">{errors.initialSupply}</div>}
                    </div>
                    <Divider />
                    <div>
                        <div className="-title s">Additional Token Properties</div>
                        <div className="-text">
                            Before creating your token, make sure your Tokenomics are in place. Select any of the options below based on your token's unique properties. You will be able to provide more details on your selection(s) later on.
                        </div>
                    </div>
                    <Card size="default" title="Token Properties">
                        <div className="-token-properties">
                            <div className="-item">
                                <div className="-text black">Burnable</div>
                                <Switch
                                    checked={formData.burnable}
                                    onChange={handleSwitchChange('burnable')}
                                />
                            </div>
                            <div className="-item">
                                <div className="-text black">Mintable</div>
                                <Switch
                                    checked={formData.mintable}
                                    onChange={handleSwitchChange('mintable')}
                                />
                            </div>
                            <div className="-item">
                                <div className="-text black">Pausable</div>
                                <Switch
                                    checked={formData.pausable}
                                    onChange={handleSwitchChange('pausable')}
                                />
                            </div>
                        </div>
                    </Card>
                    <div>
                        <div className="-title s">Token Creation Confirmation</div>
                        <div className="-text">
                            By clicking on 'Create Token' you recognize that the provided information is correct and that Forcefi does not bear any responsibility for the eventual use, distribution, and performance of your token.
                        </div>
                    </div>
                    <div className="-button-container">
                        <Button type="primary" onClick={handleTokenCreation}>Create Token</Button>
                    </div>
                </div>
            </div>
        </>
    );
}
