import React, {useEffect, useState} from 'react';
import {Button, Card, Input, Select, Table} from "antd";
import {
    claim, depositTreasuryToken,
    getCurrentStakingReward,
    getCurrentStakingRewardPerToken, getTotalStaked,
    getTotalStakedByAddress, stake, unstake
} from "../../ethers/LinearStaking";
import {approveAmount, getTotalSupply} from "../../ethers/ERC20Contract";
import erc20InvestmentTokens from "../../constants/ERC20InvestmentTokens";
import Web3 from "web3";

export default function MyTokens() {
    const columns1 = [
        {
            title: 'Token name',
            dataIndex: 'tokenName',
            key: 'tokenName',
        },
        {
            title: 'Claimable',
            dataIndex: 'claimable',
            key: 'claimable',
        },
        {
            title: 'Claimed',
            dataIndex: 'claimed',
            key: 'claimed',
        },
        {
            title: 'Remaining',
            dataIndex: 'remaining',
            key: 'remaining',
        },
        {
            title: 'Total',
            dataIndex: 'total',
            key: 'total',
        },
        {
            title: 'Next Release',
            dataIndex: 'nextRelease',
            key: 'nextRelease',
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
        },
    ];
    const columns2 = [
        {
            title: 'Stake',
            dataIndex: 'stake',
            key: 'stake',
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
        },
    ];
    const columns3 = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Currency',
            dataIndex: 'currency',
            key: 'currency',
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
        },
    ];
    const dataSource1 = [
        {
            key: '1',
            tokenName: 'Token 1',
            claimable: 10000,
            claimed: 3000,
            remaining: 140000,
            total: 143000,
            nextRelease: "12:00 08.07.2025"
        },
    ];
    const dataSource2 = [
        {
            key: '1',
            stake: 'Token2',
            amount:1000,
            date: "11:00 08.07.2025"
        },
    ];
    const dataSource3 = [
        {
            key: '1',
            name: 'Token 3',
            currency: "eth",
            amount:1000,
            date: "11:00 08.07.2025"
        },
    ];

    const [stakeAmount, setStakeAmount] = useState(0);
    const [unstakeAmount, setUnstakeAmount] = useState(0);

    const [forcReward, setForcReward] = useState(0);
    const [myForcStaked, setMyForcStaked] = useState(0);
    const [currentRewardPerToken, setCurrentRewardPerToken] = useState(0);
    const [totalForcStaked, setTotalForcStaked] = useState(0);

    useEffect(() => {
        const getLinearStakingValues = async () => {
            try {
                const currentStakingReward = await getCurrentStakingReward();
                setForcReward(Web3.utils.fromWei((currentStakingReward).toString(), "ether"));

                const totalStakedByAddress = await getTotalStakedByAddress();
                setMyForcStaked(Web3.utils.fromWei((totalStakedByAddress).toString(), "ether"));

                const currentStakingRewardPerToken = await getCurrentStakingRewardPerToken();
                setCurrentRewardPerToken(Web3.utils.fromWei((currentStakingRewardPerToken).toString(), "ether"));

                const totalStaked = await getTotalStaked();
                setTotalForcStaked(Web3.utils.fromWei((totalStaked).toString(), "ether"));
            } catch (error) {
                console.error("Error fetching staking values:", error);
            }
        };

        getLinearStakingValues();
    }, []);

    const handleStake = async() => {
        await approveAmount(erc20InvestmentTokens["11155111"].FORC_TOKEN_ADDRESS,
            erc20InvestmentTokens["11155111"].LINEAR_STAKING_ADDRESS,
            stakeAmount,
            18)
        await stake(stakeAmount);
    }

    const handleUnstake = async() => {
        await unstake(unstakeAmount);
    }

    const handleClaim = async() => {
        await claim();
    }

    return (
        <div className="tokens-wrapper">
            <Card size="default" title="FORC token Staking">
                <div className="tokens-wrapper">
                    <Input placeholder="Enter stake amount" onChange={(e) => setStakeAmount(e.target.value)}/>
                    <div>
                        <Button type="primary" onClick={handleStake}>Stake</Button>
                    </div>

                    <Input placeholder="Enter unstake amount" onChange={(e) => setUnstakeAmount(e.target.value)}/>
                    <div>
                        <Button type="primary" onClick={handleUnstake}>Unstake</Button>
                    </div>

                    <div>Current reward : {forcReward}</div>
                    <div>Current reward per token: {currentRewardPerToken}</div>
                    <div>My FORC staked : {myForcStaked}</div>
                    <div>Total FORC staked : {totalForcStaked}</div>

                    <div>
                        <Button type="primary" onClick={handleClaim}>Claim</Button>
                    </div>

                </div>
            </Card>
            {/*<Card size="default" title="Bridge Assets">*/}
            {/*    <div className="tokens-wrapper">*/}
            {/*        <div>From</div>*/}
            {/*        <Select*/}
            {/*            defaultValue="lucy"*/}
            {/*            style={{ width: '100%' }}*/}
            {/*            options={[*/}
            {/*                { value: 'jack', label: 'Jack' },*/}
            {/*                { value: 'lucy', label: 'Lucy' },*/}
            {/*                { value: 'Yiminghe', label: 'yiminghe' },*/}
            {/*                { value: 'disabled', label: 'Disabled', disabled: true },*/}
            {/*            ]}>*/}

            {/*        </Select>*/}

            {/*        <div>To</div>*/}
            {/*        <Select*/}
            {/*            defaultValue="lucy"*/}
            {/*            style={{ width: '100%' }}*/}
            {/*            options={[*/}
            {/*                { value: 'jack', label: 'Jack' },*/}
            {/*                { value: 'lucy', label: 'Lucy' },*/}
            {/*                { value: 'Yiminghe', label: 'yiminghe' },*/}
            {/*                { value: 'disabled', label: 'Disabled', disabled: true },*/}
            {/*            ]}>*/}

            {/*        </Select>*/}
            {/*        <div>*/}
            {/*            <Button type="primary">Bridge</Button>*/}
            {/*        </div>*/}

            {/*    </div>*/}
            {/*</Card>*/}
            {/*<Card size="default" title="Claim Rewards">*/}
            {/*    <Table scroll={{ x: "max-content" }} dataSource={dataSource1} columns={columns1} />*/}
            {/*</Card>*/}
            {/*<div>*/}
            {/*    <Button type="primary">Unstake</Button>*/}
            {/*</div>*/}
            {/*<Card size="default" title="Active stake">*/}
            {/*    <Table scroll={{ x: "max-content" }} dataSource={dataSource2} columns={columns2} />*/}
            {/*</Card>*/}
            {/*<Card size="default" title="Investment stake">*/}
            {/*    <Table scroll={{ x: "max-content" }} dataSource={dataSource3} columns={columns3} />*/}
            {/*</Card>*/}
        </div>
    );
}
