import React from "react";
import { Modal, Button } from "antd";

const WarningModal = ({ show, title, text, action, onClose }) => {
  return (
    <Modal
      visible={show}
      title={title}
      onCancel={onClose}
      footer={[
        <Button key="no" onClick={onClose}>
          No
        </Button>,
        <Button key="yes" type="primary" danger onClick={action}>
          Yes
        </Button>,
      ]}
    >
      <p>{text}</p>
    </Modal>
  );
};

export default WarningModal;
