import React, {useState} from 'react'
import ChainFilter from './ChainFilter'
import { Button } from 'antd';

export default function BridgingAccess() {
    const [chain, setChain] = useState("arbitrum-goerli");

  return (
    <div className='bridging-access'>
        <ChainFilter handleChangeChain={setChain} queryChain={chain} />
        <div>lor</div>
        <div>
            <Button type='primary'>Bridge</Button>
        </div>
    </div>
  )
}