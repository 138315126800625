import React, {useState} from 'react';
import {Button, Modal} from 'antd';
import '../../assets/scss/_add-beneficiar-modal.scss';
import BeneficiarManager from "../token/BenificiarManager";
import CSVUploader from "../elements/CSVUploader";
import { addVestingBeneficiar } from "../../ethers/Vesting";

export default function AddBeneficiar({ vestingIdx, show, close }) {
    const [beneficiaries, setBeneficiaries] = useState([{ address: '', amount: '' }]);
    const [isBenificiarValid, setIsBenificiarValid] = useState(false);
    const [csvData, setCsvData] = useState([]);
    const handleBeneficiarChange = (index, value) => {
        setBeneficiaries(value);
    }
    const handleSubmitBeneficiaries = async () => {
        if (isBenificiarValid) {
            const allBeneficiaries = [...csvData, ...beneficiaries].map(item => ({
                beneficiaryAddress: item.address,
                tokenAmount: (item.amount * (10 ** Number(18))).toLocaleString().replace(/,/g, ''),
            }));
            await addVestingBeneficiar (vestingIdx, allBeneficiaries)
        }
    };

    const handleCancel = () => {
        close();
    };

    return (
        <Modal
            title="Add beneficiary to project"
            visible={show}
            onOk={handleSubmitBeneficiaries}
            onCancel={handleCancel}
            footer={[
                <Button key="back" onClick={handleCancel}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" onClick={handleSubmitBeneficiaries}>
                    Submit
                </Button>,
            ]}
            closable={false}
        >
            <div>
                <BeneficiarManager
                    beneficiars={beneficiaries}
                    handleBeneficiarChange={handleBeneficiarChange}
                    index={0}
                    setIsBenificiarValid={setIsBenificiarValid}
                    validateForm={() => console.log('validate')}
                    benificiarTrigger={false}
                />
            </div>
            <CSVUploader setCsvData={setCsvData}/>
            <a
                href="/example_beneficiaries.csv"
                download
                key="download"
            >
                <Button>Download Example</Button>
            </a>
        </Modal>
    );
}
