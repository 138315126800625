import React, {useEffect} from "react";
import {createWeb3Modal, defaultWagmiConfig, useWeb3Modal, useWeb3ModalState} from '@web3modal/wagmi/react'
import {arbitrum, goerli, mainnet} from "viem/chains";
import {setLocal} from "web3modal";
import {useAccount} from "wagmi";
import {useNavigate} from "react-router-dom";

const projectId = process.env.REACT_APP_WALLET_CONNECT_ID

const metadata = {
    name: 'web3-modal-setup',
    description: 'Web3 Modal Example',
}

const chains = [mainnet, arbitrum, goerli]
const wagmiConfig = defaultWagmiConfig({ chains, projectId, metadata })

createWeb3Modal({ wagmiConfig, projectId, chains })

export default function ConnectButton(props) {
    const { open } = useWeb3Modal()
    const { open:isOpen } = useWeb3ModalState()
    const { isConnected } = useAccount()
    const navigate = useNavigate();
    function truncateText(text, length) {
        return text.length <= length ? text : text.slice(0, 5) + "..." + text.slice(-4);
    }
    useEffect(() => {
        document.documentElement.style.setProperty(
            '--scrollBar',
            isOpen ? 'stable' : 'auto'
          );
      }, [isOpen]);

    const handleOpenWeb3Modal = () => {
        if(isConnected){
            if (props.buttonType === "web3Button"){
                open()
            } else {
                props.homePageNavigation ? navigate("/registration") : navigate("/cabinet")
            }
        } else {
            setLocal("homePageNavigation", props.homePageNavigation);
            open()
        }
    }

    return (
        props.buttonType === "web3Button" ?
            <button className="connect" onClick={handleOpenWeb3Modal}>{props?.buttonName
                ? truncateText(props.buttonName, 8) : "Connect"}</button>
            :
            <button className={props.buttonType} onClick={handleOpenWeb3Modal}>{props.buttonName}</button>
    )
}
