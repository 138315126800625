// tokenHandler.js
import * as ethers from "ethers";
import ContractFactory from "../abis/ContractFactory.json";
import erc20InvestmentTokens from "../constants/ERC20InvestmentTokens";

export async function getProjectTokens(projectName) {

    const erc20Addresses = []

    try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const selectedAccount = accounts[0];
        const provider = new ethers.BrowserProvider(window.ethereum);
        const signer = await provider.getSigner(selectedAccount);
        const network = await provider.getNetwork();

        erc20Addresses.push(...await queryContractCreatedFilter(erc20InvestmentTokens[network.chainId].SIMPLE_FACTORY_ADDRESS, signer, projectName, selectedAccount, false));
        erc20Addresses.push(...await queryContractCreatedFilter(erc20InvestmentTokens[network.chainId].PAUSABLE_FACTORY_ADDRESS, signer, projectName, selectedAccount, true));

        return erc20Addresses;

    } catch (error) {
        console.error('Error while reading from smart contract:', error);
    }
}

async function queryContractCreatedFilter(factoryContractAddress, signer, projectName, selectedAccount, pausable){
    let erc20Tokens = []
    const tokenFactoryContract = new ethers.Contract(factoryContractAddress, ContractFactory, signer);
    const specificFilter = tokenFactoryContract.filters.ContractCreated(null, selectedAccount, null);

    const matchedEvents = await tokenFactoryContract.queryFilter(specificFilter, null, "latest")
    for(const event of matchedEvents){
        const contractAddress = event.args[0];
        const ownerAddress = event.args[1];
        const projectNameFromChain = event.args[2];
        const contractType = event.args[3];

        let burnable = false;
        let mintable = false;
        if (contractType.toString() === "1") {
            mintable = true;
        } else if (contractType.toString() === "2") {
            burnable = true;
        } else if (contractType.toString() === "3") {
            burnable = true;
            mintable = true;
        }

        if (projectNameFromChain === projectName && ownerAddress.toLowerCase() === selectedAccount.toLowerCase()) {
            erc20Tokens.push({
                address: contractAddress,
                burnable,
                mintable,
                pausable,
            });
        }
    }
    return erc20Tokens;
}

export async function handleCreateToken  (tokenName, ticker, projectName, initialSupply, burnable, mintable, pausable) {

    const provider = new ethers.BrowserProvider(window.ethereum);
    const network = await provider.getNetwork();

    const { contractType, tokenFactoryAddress } = calculateFactoryParams(burnable, mintable, pausable, network);

    try {
        const signer = await provider.getSigner();

        const tokenFactoryContract = new ethers.Contract(tokenFactoryAddress, ContractFactory, signer);

        // tokenFactoryContract.on("ContractCreated", (arg1, arg2, arg3, event) => {
        //     console.log("Event received:", arg1, arg2, arg3);
        //     console.log("Event:", event);
        //     // TODO: persist data in db
        // });

        const nr = (initialSupply * (10 ** Number(18))).toLocaleString();
        const normalizedTotalSupply = nr.replace(/,/g, '')

        const tx = await tokenFactoryContract.createContract(contractType, tokenName, ticker, projectName, normalizedTotalSupply,
            { value: "0" });

        await tx.wait();
        // tokenFactoryContract.off("ContractCreated", eventHandler); - switch off only attached event
    } catch (error) {
        console.error('Error writing to smart contract:', error.message);
        return error;
    }
};

const calculateFactoryParams = (burnable, mintable, pausable, network) => {
    let contractType;
    let tokenFactoryAddress;

    if (!pausable){
        tokenFactoryAddress = erc20InvestmentTokens[network.chainId].SIMPLE_FACTORY_ADDRESS;
    } else {
        tokenFactoryAddress = erc20InvestmentTokens[network.chainId].PAUSABLE_FACTORY_ADDRESS;
    }

    if (!burnable && !mintable) {
        contractType = 0;
    } else if (!burnable && mintable) {
        contractType = 1;
    } else if (burnable && !mintable) {
        contractType = 2;
    } else if (burnable && mintable) {
        contractType = 3;
    }

    return { contractType, tokenFactoryAddress };
}
