import React, {useEffect, useState} from 'react';
import "../assets/scss/_sale-page.scss";
import "../assets/scss/_components.scss";
import "../assets/scss/_layout.scss"
import {Button, Divider, message, Flex} from 'antd';
import MetamaskProgress from "../components/modals/MetamaskProgress";
import {Link, useLocation } from "react-router-dom";
import {
    claimTokens,
    closeCampaign,
    computeReleasableAmount,
    getChainlinkDataFeedLatestAnswer,
    getFundraisingInstance,
    getIndividualBalanceForToken,
    getIndividualBalances,
    getReleasedTokens,
    getVestingPlan,
    getWhitelistedTokens,
    invest,
    reclaimTokens
} from "../ethers/Fundraising";
import {approveAmount, getTokenDecimals, getTokenName, getTokenTicker, mintTestTokens} from "../ethers/ERC20Contract";
import {getSaleCampaignStatus, SaleCampaignStatus} from "../utils/SaleCampaignStatus";
import erc20InvestmentTokens from "../constants/ERC20InvestmentTokens";
import Web3 from "web3";
import * as ethers from "ethers";
import LoadingModal from "../components/modals/LoadingModal";
import {getFundraisingEntryByAddress} from "../controllers/FundraisingController";
import AddWhiteListedAddresses from "../components/modals/AddWhiteListedAddresses";
import {useTimer} from "../hooks/useTimer";
import toSeconds from "../constants/timeConstants";
import SaleSettings from "../components/sale/SaleSettings";
import SaleInfo from "../components/sale/SaleInfo";
import SaleTimer from "../components/sale/SaleTimer";
import SaleInvestment from '../components/sale/SaleInvestment';

export default function Sale() {

    const location = useLocation();
    // const navigate = useNavigate();
    const saleUUID = new URLSearchParams(location.search).get("id");

    const timer = useTimer(new Date());
    const [timerTitle, setTimerTitle] = useState("")

    const provider = new ethers.BrowserProvider(window.ethereum);
    const [network, setNetwork] = useState('')

    const [isOwner, setIsOwner] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [editSale, setEditSale] = useState(false)

    const [investmentTokensArray, setInvestmentTokensArray] = useState([])

    const[saleReleaseContainer, setSaleReleaseContainer] = useState({})

    const[investmentToken, setInvestmentToken] = useState('')
    const[investmentAmount, setInvestmentAmount] = useState('')
    const[investmentTabe, setInvestmentTabe] = useState('')
    const[decimals, setDecimals] = useState('')

    const [tokenInformation, setTokenInformation] = useState({})
    const [timeline, setTimeline] = useState({})
    const [conditions, setConditions] = useState({})
    const [campaign, setCampaign] = useState({})
    const [hasCampaignData, setHasCampaignData] = useState(false)

    const [campaignStatus, setCampaignStatus] = useState('')
    const [successModal, setSuccessModal] = useState(false);
    const [campaignClosed, setCampaignClosed] = useState(false);
    const[rate, setRate] = useState()
    const[rateDelimiter, setRateDelimiter] = useState()

    const[projectName, setProjectName] = useState("")
    const[projectEntry, setProjectEntry] = useState("")
    const[saleBlockchain, setSaleBlockchain] = useState("")

    const[whiteListedModal, setWhiteListedModal] = useState(false)

    useEffect(() => {
        const handleGetFundraisings = async () => {
            const fundraisingInstance = await getFundraisingInstance(saleUUID);
            const vestingPlan = await getVestingPlan(saleUUID);

            const signer = await provider.getSigner();
            if (fundraisingInstance[0] === signer.address){
                setIsOwner(true)
            }

            setTokenInformation({
                tokenAddress: fundraisingInstance[11],
                tokenTicker: await getTokenTicker(fundraisingInstance[11]),
                tokenName: await getTokenName(fundraisingInstance[11]),
                network: "Sepolia"
            })

            const saleStart = handleTimestampToDate(fundraisingInstance[3].toString())
            const saleEnd = handleTimestampToDate(fundraisingInstance[4].toString())
            const saleStartDate = new Date(reformatDate(saleStart));
            const saleEndDate = new Date(reformatDate(saleEnd));
            const saleReclaim = new Date(saleEndDate.getTime() + toSeconds.day * 1000);
            const now = new Date();
            if (now < saleStartDate) {
                timer.setDeadline(saleStartDate);
                setTimerTitle("Time left until token sale starts");
            } else if (now >= saleStartDate && now <= saleEndDate) {
                timer.setDeadline(saleEndDate);
                setTimerTitle("Time left until token sale ends");
            } else if (now > saleEndDate && now <= saleReclaim) {
                timer.setDeadline(saleReclaim);
                setTimerTitle("Time left until reclaim phase is available");
            } else {
                setTimerTitle("Sale ends");
            }
            setTimeline({
                startDate: saleStart,
                endDate: saleEnd
            })

            setConditions({
                minimalInvestment: fundraisingInstance[8].toString(),
                cliffPeriod: vestingPlan[2].toString(),
                vestingPeriod: vestingPlan[3].toString(),
                releasePeriod: vestingPlan[4].toString(),
                tge: vestingPlan[5].toString(),
            })

            setCampaign({
                hardCap: fundraisingInstance[5].toString(),
                tokenAllocated: fundraisingInstance[1].toString(),
                saleType: fundraisingInstance[2] ? "public" : "private"
            })

            setCampaignStatus(getSaleCampaignStatus(fundraisingInstance[3].toString(),
                fundraisingInstance[4].toString(),
                fundraisingInstance[1].toString()))

            // Post-campaign data for claiming tokens
            const releasedTokens = await getReleasedTokens(saleUUID, signer.getAddress())
            const totalTokensAllocated = await getIndividualBalances(saleUUID)
            const claimableTokens = await computeReleasableAmount(saleUUID)

            // Calculate next release date
            let currentDateTimestamp = Math.floor(Date.now() / 1000);
            const vestingStart = vestingPlan[1]
            const cliffPeriod = vestingPlan[2]
            const vestingPeriod = vestingPlan[3]
            const releasePeriod = vestingPlan[4]
            const lastReleaseDate = vestingStart + cliffPeriod + vestingPeriod

            let releaseDate = vestingStart;

            if (currentDateTimestamp > lastReleaseDate){
                releaseDate = lastReleaseDate
            } else if(currentDateTimestamp > vestingStart + cliffPeriod){

                const vestingStartNormalized = Number(vestingStart)
                const releasePeriodNormalized = Number(releasePeriod)
                const currentDateInSeconds = Math.floor(Date.now() / 1000)

                const periodsPassed = Math.floor((currentDateInSeconds - vestingStartNormalized) / releasePeriodNormalized)
                releaseDate = vestingStartNormalized + ((periodsPassed + 1) * releasePeriodNormalized)
            }

            const releaseContainer = {
                "tokensReleased": Web3.utils.fromWei(releasedTokens.toString(), "ether"),
                "totalTokensAllocated": Web3.utils.fromWei(totalTokensAllocated.toString(), "ether"),
                "claimableTokens": Web3.utils.fromWei(claimableTokens.toString(), "ether"),
                "nextReleaseDate": handleTimestampToDate(releaseDate)
            }

            setCampaignClosed(fundraisingInstance[9])
            setRate(fundraisingInstance[6])
            setRateDelimiter(fundraisingInstance[7])

            setSaleReleaseContainer(releaseContainer)

            setHasCampaignData(true)
        }
        if (!successModal){
            handleGetFundraisings()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(() => {
        const handleGetInvestmentTokens = async () => {
            const investmentTokens = await getWhitelistedTokens(saleUUID)
            const addressArray = investmentTokens.toString().split(',');
            let investTable = [];
            let tokenNameArray = [];
            for (const address of addressArray) {
                const investmentTokenAddress = address;
                const individualBalance = await getIndividualBalanceForToken(saleUUID, address)
                const balanceInEth = Web3.utils.fromWei(individualBalance.toString());
                let tokenName = await getTokenName(address);
                let tokenTicker = await getTokenTicker(address);

                investTable = [...investTable,
                    {
                        investmentTokenAddress: investmentTokenAddress,
                        tokenName: tokenName,
                        tokenTicker: tokenTicker,
                        balanceEth: balanceInEth,
                        actionButton: campaignStatus === SaleCampaignStatus.FAILED
                    },
                ]
                tokenNameArray.push(tokenTicker)
            }
            setInvestmentTabe(investTable)
            setInvestmentTokensArray(tokenNameArray)
            setInvestmentToken(erc20InvestmentTokens[network.chainId][tokenNameArray[0]]);
            setDecimals(await getTokenDecimals(erc20InvestmentTokens[network.chainId][tokenNameArray[0]]))
        }

        if (campaignStatus !== SaleCampaignStatus.PRE_SALE){
            handleGetInvestmentTokens();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[campaignStatus, successModal])

    const getFundraisingByAddress = async () => {
        try {
            const fundraisingFromDb = await getFundraisingEntryByAddress(saleUUID);
            setProjectName(fundraisingFromDb.projectName);
            setProjectEntry(fundraisingFromDb.entry)
            setSaleBlockchain(fundraisingFromDb.entry.network);
        } catch (error) {
            // navigate('/unauthorized');
        }
    };

    useEffect(() => {
        const handleSetNetwork = async () => {
            setNetwork(await provider.getNetwork())
        }
        getFundraisingByAddress()
        handleSetNetwork()
        getFundraisingByAddress()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function reformatDate(dateStr) {
        const [datePart, timePart] = dateStr.split(', ');
        const [day, month, year] = datePart.split('/');
        return `${month}/${day}/${year}, ${timePart}`;
    }

    const getTestTokens = async (investmentTokenAddress) => {
        await mintTestTokens(investmentTokenAddress, "10000000000000000000000");
    }

    const handleChangeInvestmentToken = async (e) => {
        setInvestmentToken(erc20InvestmentTokens[network.chainId][e.target.value]);
        setDecimals(await getTokenDecimals(erc20InvestmentTokens[network.chainId][e.target.value]))
    }


    const handleTimestampToDate = (timestamp) => {
        const date = new Date(Number(timestamp) * 1000);
        return date.toLocaleString();
    };

    const [step, setStep] = useState(0)

    const handleInvest = async () => {
        setSuccessModal(true)
        const calculatedApproveAmount = investmentAmount * Number(await getChainlinkDataFeedLatestAnswer(investmentToken)) * Number(rate) / Number(rateDelimiter) / Math.pow(10, Number(decimals));
        const approveTx = await approveAmount(investmentToken, erc20InvestmentTokens[network.chainId].FUNDRAISING_ADDRESS, calculatedApproveAmount, decimals)
        if (approveTx !== undefined){
            const errorMsg = approveTx.toString().split('(')
            message.error(errorMsg[0])
            setStep(0)
        } else {
            setStep(1)
            const tx = await invest(investmentAmount, investmentToken, saleUUID, decimals);
            if (tx !== undefined){
                const errorMsg = tx.toString().split('(')
                message.error(errorMsg[0])
                setStep(0)
            } else {
                setStep(2)
                setSuccessModal(false)
            }
        }
    }

    const handleReclaimTokens = async () => {
        setIsLoading(true);
        await reclaimTokens(saleUUID)
        setIsLoading(false);
    }

    const handleClaimTokens = async () => {
        setIsLoading(true);
        await claimTokens(saleUUID)
        setIsLoading(false);
    }

    const handleCloseCampaign = async () => {
        setIsLoading(true);
        await closeCampaign(saleUUID)
        setIsLoading(false);
    }


    return (
        <>
            <LoadingModal  show={isLoading} text='Handling event...' />
            <AddWhiteListedAddresses show={whiteListedModal} close={() => setWhiteListedModal(false)} />
            <MetamaskProgress selectedToken={investmentToken}
                              decimals={decimals}
                              show={successModal}
                              investmentAmount={investmentAmount}
                              saleUUID={saleUUID}
                              close={() => setSuccessModal(false)}
                              step={step}/>
            { projectEntry.saleBackground
                ?   <div className='back home' style={{ backgroundImage: `url(${projectEntry.saleBackground})` }} />
                :   <div className="back home" />
            }
            <div className="main-container">
                <div className="sale">
                    {editSale 
                        ? <SaleSettings close={() => setEditSale(false)} saleId={saleUUID} updateSaleInfo={getFundraisingByAddress} saleInfo={projectEntry}/>
                        : <>
                        <Link to={`/project?id=${projectName}`}>
                         <Button className="-to-project" type="primary">Go to Project</Button>
                        </Link>
                        <div>
                            <div className="-title offset centrate">
                                {projectName} Token Sale Page <Button onClick={() => setEditSale(true)}>Edit</Button>
                            </div>
                            <div className="-text">
                                {projectEntry.description}
                            </div>
                        </div>
                        <SaleInfo
                            tokenInformation={tokenInformation}
                            campaign={campaign}
                            conditions={conditions}
                            timeline={timeline}
                        />
                        { timerTitle !== "Sale ends" && timerTitle !== "" &&
                            <SaleTimer
                                timerTitle={timerTitle} 
                                saleBlockchain={saleBlockchain} 
                                campaign={campaign} 
                                timer={timer} 
                                hasCampaignData={hasCampaignData} 
                            />
                        }
                        <Divider />
                        <div>
                            <div className="-title s">Campaign Information</div>
                            <div className="-text">Please review the information below to prepare for the sale'</div>
                        </div>

                        <div>
                        {/* TODO: when private*/}
                        <Button type="primary" onClick={() =>setWhiteListedModal(true)}>Whitelist participants</Button>
                        </div>
                        <SaleInvestment
                            projectEntry={projectEntry}
                            campaignStatus={campaignStatus}
                            investmentTokensArray={investmentTokensArray}
                            saleReleaseContainer={saleReleaseContainer}
                            campaignClosed={campaignClosed}
                            isOwner={isOwner}
                            handleInvest={handleInvest}
                            handleCloseCampaign={handleCloseCampaign}
                            handleClaimTokens={handleClaimTokens}
                            investmentTabe={investmentTabe}
                            handleReclaimTokens={handleReclaimTokens}
                            setInvestmentAmount={setInvestmentAmount}
                            handleChangeInvestmentToken={handleChangeInvestmentToken}
                        />
                    </>
                }
                </div>
            </div>
            <Flex gap={12} wrap='wrap'>
                <Button onClick={() =>getTestTokens (erc20InvestmentTokens[network.chainId].USDT)}>Get USDT tokens</Button>
                <Button onClick={() =>getTestTokens (erc20InvestmentTokens[network.chainId].USDC)}>Get USDC tokens</Button>
                <Button onClick={() =>getTestTokens (erc20InvestmentTokens[network.chainId].DAI)}>Get DAI tokens</Button>
            </Flex>
        </>
    )
}