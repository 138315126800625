import React from 'react';
import "../assets/scss/_components.scss";
import {Button, Dropdown, Menu} from 'antd';
import {CheckOutlined} from '@ant-design/icons';
import ArrowDown from "../assets/icons/arrow-down.svg";
import {chainIcons} from "../utils/chainIcons";
import Arthera from "../assets/icons/header/arthera.svg";
import Zetachain from "../assets/icons/header/zetachain.svg";
import Scroll from "../assets/icons/header/scroll.svg";
import {changeNetwork} from "../utils/metamaskNetworkConfigs";
import {useNetwork, useSwitchNetwork} from "wagmi";

const ChainSelector = () => {
    const { chain } = useNetwork();
    const { chains, isLoading, pendingChainId, switchNetwork } = useSwitchNetwork();

    const handleNetworkSwitch = async (networkName) => {
        await changeNetwork({ networkName });
    };

    const handleChangeNetwork = (networkId) => {
        switchNetwork?.(networkId);
    };
    const NetworkItem = ({ networkName, networkImage, checkChainId}) => (
        <div
            className="-network-select"
            onClick={() => handleNetworkSwitch(networkName)}
            style={{ display: "flex", alignItems: "center" }}
        >
            <img src={networkImage} alt={networkName} style={{ marginRight: 8 }} />
            {networkName}
            {chain.name === checkChainId && <CheckOutlined style={{ marginLeft: 'auto' }} />}
        </div>
    );

    const menuItems = [
        ...chains.map((x) => ({
            key: x.id,
            label: (
                <div
                    className="-network-select"
                    onClick={() => (x.id === chain?.id ? null : handleChangeNetwork(x.id))}
                    style={{ display: "flex", alignItems: "center" }}
                >
                    <img src={chainIcons[x.name]} className='-img' alt="" style={{ marginRight: 8 }} />
                    {x.name} {isLoading && pendingChainId === x.id && ' (switching)'}
                    {x.id === chain?.id && <CheckOutlined style={{ marginLeft: 'auto' }} />}
                </div>
            ),
            disabled: !switchNetwork,
        })),
        {
            type: 'divider',
        },
        {
            key: 'testnet-arthera',
            label: (
                <NetworkItem networkName='arthera' networkImage={Arthera} checkChainId='Chain 10243' />
            ),
        },
        {
            key: 'testnet-zetachain',
            label: (
                <NetworkItem networkName='zetachain' networkImage={Zetachain} checkChainId='Chain 7001' />
            ),
        },
        {
            key: 'testnet-scroll',
            label: (
                <NetworkItem networkName='scroll' networkImage={Scroll} checkChainId='Chain 534351' />
            ),
        },
    ];

    return (
        <Dropdown
            overlay={<Menu items={menuItems} />}
            trigger={['click']}
            placement="bottom"
        >
            <Button
                className='-network-select'
                type="text"
                style={{ display: 'flex', alignItems: 'center', backgroundColor: 'transparent', padding: '4px' }}
            >
                <img src={chainIcons[chain?.name]} alt="" />
                <img src={ArrowDown} alt="" className='-arrow' style={{ marginRight: 8 }} />
            </Button>
        </Dropdown>
    );
}

export default ChainSelector;